import React from "react";
import { Route, Switch, Redirect } from "react-router";
import { ConnectedRouter } from "connected-react-router";
import { connect } from "react-redux";

import { checkLoggedIn } from "src/redux/selectors";
import TemplateNothing from "src/components/layout/TemplateNothing";
import TemplateSidebar from "src/components/layout/TemplateSidebar";

import SignInContainer from "src/containers/SignIn";
import SignInComponent from "src/components/views/SignIn";
import SignUpContainer from "src/containers/SignUp";
import SignUpComponent from "src/components/views/SignUp";

import UserDashboardContainer from "src/containers/restricted/user/Dashboard";
import UserDashboardComponent from "src/components/views/user/Dashboard";
import UserAccountSettingsContainer from "src/containers/restricted/user/AccountSettings";
import UserAccountSettingsComponent from "src/components/views/user/AccountSettings";
import UserRequisitionContainer from "src/containers/restricted/user/Requisition";
import UserRequisitionComponent from "src/components/views/user/Requisition";
import UserStockContainer from "src/containers/restricted/user/Stock";
import UserStockComponent from "src/components/views/user/Stock";
import UserPexUnitsContainer from "src/containers/restricted/user/PexUnits";
import UserPexUnitsComponent from "src/components/views/user/PexUnits";
import UserRequestContainer from "src/containers/restricted/user/Request";
import UserRequestComponent from "src/components/views/user/Request";
import UserPexRequestContainer from "src/containers/restricted/user/PexRequest";
import UserPexRequestComponent from "src/components/views/user/PexRequest";
import UsersContainer from "src/containers/restricted/user/Users";
import UsersComponent from "src/components/views/user/Users";

import BuyerDashboardContainer from "src/containers/restricted/buyer/Dashboard";
import BuyerDashboardComponent from "src/components/views/buyer/Dashboard";
import BuyerAccountSettingsContainer from "src/containers/restricted/buyer/AccountSettings";
import BuyerAccountSettingsComponent from "src/components/views/buyer/AccountSettings";
import BuyerRequisitionContainer from "src/containers/restricted/buyer/Requisition";
import BuyerRequisitionComponent from "src/components/views/buyer/Requisition";
import BuyerStockContainer from "src/containers/restricted/buyer/Stock";
import BuyerStockComponent from "src/components/views/buyer/Stock";
import BuyerPexUnitsContainer from "src/containers/restricted/buyer/PexUnits";
import BuyerPexUnitsComponent from "src/components/views/buyer/PexUnits";
import BuyerRequestContainer from "src/containers/restricted/buyer/Request";
import BuyerRequestComponent from "src/components/views/buyer/Request";
import BuyerPexRequestContainer from "src/containers/restricted/buyer/PexRequest";
import BuyerPexRequestComponent from "src/components/views/buyer/PexRequest";

import ManagerDashboardContainer from "src/containers/restricted/manager/Dashboard";
import ManagerDashboardComponent from "src/components/views/manager/Dashboard";
import ManagerAccountSettingsContainer from "src/containers/restricted/manager/AccountSettings";
import ManagerAccountSettingsComponent from "src/components/views/manager/AccountSettings";
import ManagerHistoryContainer from "src/containers/restricted/manager/History";
import ManagerHistoryComponent from "src/components/views/manager/History";

import AdminDashboardContainer from "src/containers/restricted/admin/Dashboard";
import AdminDashboardComponent from "src/components/views/admin/Dashboard";
import AdminAccountSettingsContainer from "src/containers/restricted/admin/AccountSettings";
import AdminAccountSettingsComponent from "src/components/views/admin/AccountSettings";
import AdminStockContainer from "src/containers/restricted/admin/Stock";
import AdminStockComponent from "src/components/views/admin/Stock";
import AdminPexUnitsContainer from "src/containers/restricted/admin/PexUnits";
import AdminPexUnitsComponent from "src/components/views/admin/PexUnits";
import AdminRequestContainer from "src/containers/restricted/admin/Request";
import AdminRequestComponent from "src/components/views/admin/Request";
import AdminPexRequestContainer from "src/containers/restricted/admin/PexRequest";
import AdminPexRequestComponent from "src/components/views/admin/PexRequest";
import AdminEvaluationContainer from "src/containers/restricted/admin/Evaluation";
import AdminEvaluationComponent from "src/components/views/admin/Evaluation";
import AdminUsersContainer from "src/containers/restricted/admin/Users";
import AdminUsersComponent from "src/components/views/admin/Users";

const RestrictedRoute = ({
  layout,
  template: Template,
  container: Container,
  component: Component,
  isLoggedIn,
  ...rest
}) => (
  <Route
    {...rest}
    render={props =>
      isLoggedIn ? (
        <Template {...props} background={rest.background}>
          <Container {...props} Layout={layout} />
        </Template>
      ) : (
        <Redirect to={{ pathname: "/", state: { from: props.location } }} />
      )
    }
  />
);

const OpenRoute = ({
  layout,
  template: Template,
  container: Container,
  component: Component,
  ...rest
}) => (
  <Route
    {...rest}
    render={props => (
      <Template {...props} background={rest.background}>
        <Container {...props} Layout={layout} />
      </Template>
    )}
  />
);
const Routes = ({ history, isLoggedIn }) => {
  return (
    <ConnectedRouter history={history}>
      <Switch>
        <OpenRoute
          path="/signin"
          template={TemplateNothing}
          container={SignInContainer}
          layout={SignInComponent}
        />
        <OpenRoute
          path="/request"
          template={TemplateNothing}
          container={AdminRequestContainer}
          layout={AdminRequestComponent}
        />
        <OpenRoute
          exact
          path="/"
          template={TemplateNothing}
          // container={AdminRequestContainer}
          // layout={AdminRequestComponent}
          container={SignInContainer}
          layout={SignInComponent}
        />
        <RestrictedRoute
          path="/user/account"
          template={TemplateSidebar}
          container={UserAccountSettingsContainer}
          layout={UserAccountSettingsComponent}
          isLoggedIn={isLoggedIn}
          background
        />
        <RestrictedRoute
          path="/user/stock"
          template={TemplateSidebar}
          container={UserStockContainer}
          layout={UserStockComponent}
          isLoggedIn={isLoggedIn}
          background
        />
        <RestrictedRoute
          path="/user/pexunits"
          template={TemplateSidebar}
          container={UserPexUnitsContainer}
          layout={UserPexUnitsComponent}
          isLoggedIn={isLoggedIn}
          background
        />
        <RestrictedRoute
          path="/user/requests"
          template={TemplateSidebar}
          container={UserRequestContainer}
          layout={UserRequestComponent}
          isLoggedIn={isLoggedIn}
          background
        />
        <RestrictedRoute
          path="/user/pexrequests"
          template={TemplateSidebar}
          container={UserPexRequestContainer}
          layout={UserPexRequestComponent}
          isLoggedIn={isLoggedIn}
          background
        />
        <RestrictedRoute
          path="/user/requisition"
          template={TemplateSidebar}
          container={UserRequisitionContainer}
          layout={UserRequisitionComponent}
          isLoggedIn={isLoggedIn}
          background
        />
        <RestrictedRoute
          path="/user/users"
          template={TemplateSidebar}
          container={UsersContainer}
          layout={UsersComponent}
          isLoggedIn={isLoggedIn}
          background
        />
        <RestrictedRoute
          path="/user/dashboard"
          template={TemplateSidebar}
          container={UserDashboardContainer}
          layout={UserDashboardComponent}
          isLoggedIn={isLoggedIn}
          background
        />
        <RestrictedRoute
          path="/buyer/account"
          template={TemplateSidebar}
          container={BuyerAccountSettingsContainer}
          layout={BuyerAccountSettingsComponent}
          isLoggedIn={isLoggedIn}
          background
        />
        <RestrictedRoute
          path="/buyer/stock"
          template={TemplateSidebar}
          container={BuyerStockContainer}
          layout={BuyerStockComponent}
          isLoggedIn={isLoggedIn}
          background
        />
        <RestrictedRoute
          path="/buyer/pexunits"
          template={TemplateSidebar}
          container={BuyerPexUnitsContainer}
          layout={BuyerPexUnitsComponent}
          isLoggedIn={isLoggedIn}
          background
        />
        <RestrictedRoute
          path="/buyer/requests"
          template={TemplateSidebar}
          container={BuyerRequestContainer}
          layout={BuyerRequestComponent}
          isLoggedIn={isLoggedIn}
          background
        />
        <RestrictedRoute
          path="/buyer/pexrequests"
          template={TemplateSidebar}
          container={BuyerPexRequestContainer}
          layout={BuyerPexRequestComponent}
          isLoggedIn={isLoggedIn}
          background
        />
        <RestrictedRoute
          path="/buyer/requisition"
          template={TemplateSidebar}
          container={BuyerRequisitionContainer}
          layout={BuyerRequisitionComponent}
          isLoggedIn={isLoggedIn}
          background
        />
        <RestrictedRoute
          path="/admin/dashboard"
          template={TemplateSidebar}
          container={AdminDashboardContainer}
          layout={AdminDashboardComponent}
          isLoggedIn={isLoggedIn}
          background
        />
        <RestrictedRoute
          path="/admin/account"
          template={TemplateSidebar}
          container={AdminAccountSettingsContainer}
          layout={AdminAccountSettingsComponent}
          isLoggedIn={isLoggedIn}
          background
        />
        <RestrictedRoute
          path="/admin/stock"
          template={TemplateSidebar}
          container={AdminStockContainer}
          layout={AdminStockComponent}
          isLoggedIn={isLoggedIn}
          background
        />
        <RestrictedRoute
          path="/admin/pexunit"
          template={TemplateSidebar}
          container={AdminPexUnitsContainer}
          layout={AdminPexUnitsComponent}
          isLoggedIn={isLoggedIn}
          background
        />
        <RestrictedRoute
          path="/admin/requests"
          template={TemplateSidebar}
          container={AdminRequestContainer}
          layout={AdminRequestComponent}
          isLoggedIn={isLoggedIn}
          background
        />
        <RestrictedRoute
          path="/admin/pexrequests"
          template={TemplateSidebar}
          container={AdminPexRequestContainer}
          layout={AdminPexRequestComponent}
          isLoggedIn={isLoggedIn}
          background
        />
        <RestrictedRoute
          path="/admin/evaluation"
          template={TemplateSidebar}
          container={AdminEvaluationContainer}
          layout={AdminEvaluationComponent}
          isLoggedIn={isLoggedIn}
          background
        />
        <RestrictedRoute
          path="/admin/users"
          template={TemplateSidebar}
          container={AdminUsersContainer}
          layout={AdminUsersComponent}
          isLoggedIn={isLoggedIn}
          background
        />
        {/* <RestrictedRoute
          path="/admin/auction"
          template={TemplateSidebar}
          container={AdminAuctionContainer}
          layout={AdminAuctionComponent}
          isLoggedIn={isLoggedIn}
          background
        /> */}
        {/* <RestrictedRoute
          path="/admin/auctionhistory"
          template={TemplateSidebar}
          container={AdminAuctionHistoryContainer}
          layout={AdminAuctionHistoryComponent}
          isLoggedIn={isLoggedIn}
          background
        />
        <RestrictedRoute
          path="/admin/deals"
          template={TemplateSidebar}
          container={AdminDealsContainer}
          layout={AdminDealsComponent}
          isLoggedIn={isLoggedIn}
          background
        /> */}
      </Switch>
    </ConnectedRouter>
  );
};

export default connect(state => ({
  isLoggedIn: checkLoggedIn(state),
}))(Routes);
